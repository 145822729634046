<template>
  <el-card shadow="always">
    <template #header>
      <div class="card-header">
        <h3>{{ title }}</h3>
      </div>
    </template>
    <el-table :data="filterTableData" style="height: 800px;">
      <el-table-column label="账号" prop="name" />
      <el-table-column label="接入好友数" prop="friends_amount" />
      <el-table-column label="有效线索量" prop="leads_avalidate_amount" />
      <el-table-column label="线索量" prop="leads_amount" />
      <el-table-column align="right">
        <template #header>
          <el-input
            v-model="search"
            size="small"
            placeholder="检索账号"
          />
        </template>
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
  <script>
import { props, ref, onMounted, nextTick, onUnmounted, computed } from "vue";
import * as echarts from "echarts";

export default {
  name: "myleads_list",
  components: {},
  props: { title: { type: String, default: "" } },
  setup(props) {
    const search = ref("");
    const filterTableData = computed(() =>
      tableData.filter(
        (data) =>
          !search.value ||
          data.name.toLowerCase().includes(search.value.toLowerCase())
      )
    );
    const handleEdit = (index, row) => {
      console.log(index, row);
    };

    const tableData = [
      {
        name: "旅行记官方账号",
        friends_amount:455,
        leads_amount: 100,
        leads_avalidate_amount: 80,
      },
      {
        name: "广之旅",
        friends_amount:455,
        leads_amount: 50,
        leads_avalidate_amount: 80,
      },
      {
        name: "贵州专线",
        friends_amount:455,
        leads_amount: 88,
        leads_avalidate_amount: 80,
      },
      {
        name: "爱旅行",
        friends_amount:455,
        leads_amount: 432,
        leads_avalidate_amount: 80,
      },
    ];

    // --------------- 方法 -----------------
  
    return {
      filterTableData,
      search,
      handleEdit,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
.el-card {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep(.el-card__body) {
    padding: 0px !important;
  }
}
::v-deep(.el-card__header) {
  background-color: rgba(154, 103, 0, 0.6) !important;
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>