<template>
  <el-card shadow="always">
    <template #header>
      <div class="card-header">
        <h3>{{title}}</h3>
      </div>
    </template>
    <div style="height: 200px" id="monthLeads"></div>
  </el-card>
</template>
<script>
import { props, ref, onMounted, nextTick, onUnmounted } from "vue";
import * as echarts from "echarts";

export default {
  name: "myleads_today",
  components: { },
  props: { title: { type: String, default: "" } },
  setup(props) {
    const handleInitPage = () => {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("monthLeads"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
            1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
         
          },
        ],
        series: [
          {
            name: "线索数量",
            type: "bar",
             // 此系列自己的调色盘。
            color: [
              '#9a6700'
            ],
            barWidth: "30%",
            data: [
            118,89,36,238,26,65,225,110,52,271,240,295,236,269,23,59,164,122,280,221,172,282,49,266,145,26,82,198,142,119,51
            ],
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };
    onMounted(handleInitPage);
    return {
        handleInitPage
    };
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  background-color: rgba(255,255,255,.1);
}
::v-deep(.el-card__header) {
  background-color: rgba(154,103,0,.6) !important;
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>