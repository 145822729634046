<template>
  <el-card shadow="always">
    <template #header>
      <div class="card-header">
        <h3>{{title}}</h3>
      </div>
    </template>
    <div style="height: 200px" id="todayLeads"></div>
  </el-card>
</template>
<script>
import { props, ref, onMounted, nextTick, onUnmounted } from "vue";
import * as echarts from "echarts";

export default {
  name: "myleads_today",
  components: { },
  props: { title: { type: String, default: "" } },
  setup(props) {
    const handleInitPage = () => {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("todayLeads"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "00",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
         
          },
        ],
        series: [
          {
            name: "线索数量",
            type: "bar",
             // 此系列自己的调色盘。
            color: [
              '#6610f2'
            ],
            barWidth: "30%",
            data: [
              113, 66, 52, 144, 121, 184, 168, 129, 157, 74, 156, 149, 27, 185,
              106, 167, 90, 40, 168, 122, 185, 56, 173, 97,
            ],
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };
    onMounted(handleInitPage);
    return {
        handleInitPage
    };
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  background-color: rgba(255,255,255,.1);
}
::v-deep(.el-card__header) {
  background-color: rgba(102,16,242,.6) !important;
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>